var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "questionnaire-portal__error" },
      [
        _vm.displayTickImage
          ? _c("div", { staticClass: "img-container" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/tick-image.png"),
                  alt: "Tick Image",
                },
              }),
            ])
          : _vm._e(),
        _c("mds-empty-state", {
          attrs: { title: _vm.error, message: _vm.message },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }