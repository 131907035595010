<template>
<div>
    <div class="questionnaire-portal__error">
        <div class="img-container" v-if="displayTickImage">
            <img src="../assets/tick-image.png" alt="Tick Image">
        </div>
        <mds-empty-state
            :title="error"
            :message="message"
        ></mds-empty-state>
    </div>
</div>
</template>

<script>

import AppConstants from '../constants/constants.json';
import labels from '../config/labels.json';
import MdsEmptyState from "@mds/empty-state";
import { logError } from '../service/questionnaireService';

export default {
    name: 'Error',
    props: {
        parentMwcId: String,
        errorMessage: String,
        description: String,
        languageId: {
            type: String,
            default: AppConstants.defaultLanguage
        },
    },
    components: {
        MdsEmptyState
    },
    data() {
        return {
            displayTickImage: false,
            labels: labels,
            error: labels[this.languageId].commonErrorMessage2,
            message: labels[this.languageId].commonErrorMessage1
        }
    },
    async mounted() {
        this.displayTickImage = false;
        if(this.errorMessage){
            if (this.errorMessage === labels[this.languageId].surveyCompleted) {
                this.displayTickImage = true;
            }
            this.error = this.errorMessage;
        }
        if(this.description) {
            this.message = this.description;
        }

       await logError({
            type: 'ERROR',
            log: `${this.error},${this.message}`
        })

    }
};
</script>

<style lang="scss">
@import "@mds/typography";

.questionnaire-portal__error{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;

    position: absolute;
    width: 40%;
    height: 72px;
    left: 30%;
    top: 45%;

    .mds-empty-state__pp {
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
    }

    .mds-empty-state__pp .mds-empty-state__title__pp {
        @include mds-level-3-heading();
        // width: 500px;
        height: auto;
        text-align: center;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .mds-empty-state__pp .mds-empty-state__message__pp {
        @include mds-body-text-l;
        display: flex;   
        height: 26px;
        text-align: center;
        flex-direction: column;
        max-width: 100%;
        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
}
</style>
